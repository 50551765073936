import React from 'react'
import MainLayout from "../layouts/MainLayout"
import Seo from "../components/seo"
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Grid, Container } from '@material-ui/core';
import ReservationConfirmed from '../components/checkout/ReservationConfirmed';
import AvatarSuccess from './../images/avatar-success.png';

export default function SuccessPayment({ location }) {
    const { t } = useTranslation();
    const data = location.state?.data || '';

    if (!data) {
        if (typeof window !== 'undefined') {
            navigate('/reserve');
            return <></>
        }
    };

    // dataTransaction
    return (
        <MainLayout withMargin={false}>
            <Seo title={t('Success Payment')} />
            <Grid container className='check-out'>
                <Grid container item xs={12} sm={6} className={'check-out-item'}>
                    <Container 
                    data-sal="slide-right"
                    data-sal-duration="2000"
                    >
                        <Grid container className={'check-out-container'} alignItems={'center'}>
                            <Grid container item xs={12} justify={'center'} direction={'column'} className='reservation-success-detail'>
                                <ReservationConfirmed data={data} />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                <Grid container item xs={12} sm={6} className={'check-out-item'} alignItems={'center'}>
                    <Container
                    data-sal="slide-left"
                    data-sal-duration="2000"
                    >
                        <Grid container className={'check-out-container'} alignItems={'center'}>
                            <Grid container item xs={12} alignItems={'center'} justify='center'>
                                <img src={AvatarSuccess} alt='success'/>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </MainLayout>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;