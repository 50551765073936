import React, { useContext, useState } from 'react' 
import { Button } from '@material-ui/core'
import { useTranslation, Link, I18nextContext } from 'gatsby-plugin-react-i18next';
import IconCheckout1 from '../../../images/icon-checkout-1.png'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ConfirmedIcon from '../../../images/icon-confirmed.png';
import { getDateDetailFromDB, getTimeDetailFromDB } from '../../../utils/datetime';

export default function ReservationConfirmed({ data }) {
    const { t } = useTranslation();
    const { language } = useContext(I18nextContext);
    return (
        <>
            <br></br>
            <br></br>
            <br></br>
            <div>
                <img src={ConfirmedIcon} />
            </div>
            <div>
                <h1>{t('Reservation')} <br></br>{t('Confirmed')}</h1>
            </div>
            <p>{t('Reservation Message')}</p>
            <p><img src={IconCheckout1} /><span>{`${data?.base?.base_iata_code} ${data?.base?.base_name}`}</span></p>
            <br></br>
            <div className='chekout-date-limits'>
                <div>
                    <p>{t('Checkout From')}</p>
                    <h2>{getDateDetailFromDB(data?.book_init_datetime,language)}</h2>
                    <p>{getTimeDetailFromDB(data?.book_init_datetime)}</p>
                </div>
                <div className='chekout-duration'>
                    <AddCircleOutlineIcon />
                    <p>
                        {`${data?.book_duration_hours} ${t('Hours')}`}
                        {(data?.book_duration_minutes !== 0) ? ` ${data?.book_duration_minutes} ${t('Minutes')}` : ''}
                    </p>
                </div>
                <div>
                    <p>{t('Checkout To')}</p>
                    <h2>{getDateDetailFromDB(data?.book_end_datetime,language)}</h2>
                    <p>{getTimeDetailFromDB(data?.book_end_datetime)}</p>
                </div>
            </div>
            <br></br>
            <div className='chekout-cabin'>
                <div className='chekout-cabin-number'>
                    <p>{t('Cabin')}</p>
                    <h2>{data?.cabin?.cabin_id}</h2>
                </div>
                <div className='chekout-cabin-password'>
                    <p>{t('Form Password')}</p>
                    <h2>{data?.book_password}</h2>
                </div>
            </div>
            <div className='total'><p>{t('Total Payment')}</p><h2>{`$${data?.book_total}`}</h2></div>
            <p>{t('Reservation Thanks')}</p>
            <br></br>
            <Link to='/'>
                <Button
                    variant='contained'
                    color="primary"
                >
                    {t('Finish')}
                </Button>
            </Link>
        </>
    )
}
